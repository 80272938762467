.primary-color {
  color: var(--primary-color);
}

.success-color {
  color: var(--success-color) !important;
}

.danger-color {
  color: var(--danger-color) !important;
}
