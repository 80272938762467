.sidebar {
  .logo {
    padding: 28px 24px 36px;

    a {
      display: block;

      svg {
        height: 36px;
        width: auto;
      }
    }
  }

  .menu {
    padding: 0 24px;

    > ul {
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        > a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 12px 0;
          text-decoration: none;
          outline: none;
          transition: all 0.3s ease-in-out;

          .name,
          .icon,
          .extraIcon {
            color: var(--main-text-color);
            font-size: 16px;
            line-height: 18px;
            font-weight: 400;
            transition: all 0.3s ease-in-out;
          }

          .name {
          }

          .icon {
            font-size: 20px;
            line-height: 1;
            margin-right: 12px;
            margin-top: -3px;
          }

          .extraIcon {
            margin-left: auto;
          }

          &:hover {
            transition: all 0.3s ease-in-out;

            .name,
            .icon {
              color: var(--primary-color);
              transition: all 0.3s ease-in-out;
            }
          }
        }

        > ul {
          height: 0;
          opacity: 0;
          overflow: hidden;
          padding: 0;
          margin: 0;
          list-style: none;
          padding-left: 36px;
          visibility: hidden;
          transition: all 0.3s ease-in-out;

          > li {
            padding: 12px 0;

            > a {
              color: var(--main-text-color);
              font-size: 16px;
              line-height: 18px;
              font-weight: 400;
              transition: all 0.3s ease-in-out;

              &:hover {
                color: var(--primary-color);
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }

      li[data-active='true'] a {
        .name,
        .icon {
          color: var(--primary-light-color);
          transition: all 0.3s ease-in-out;
        }
      }

      > li[data-expanded='true'] {
        .extraIcon {
          transform: rotate(180deg);
        }

        > ul {
          height: auto;
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
