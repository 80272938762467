.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

.ant-spin-nested-loading {
  > div {
    height: 100%;

    .ant-spin {
      max-height: 9999px;

      .ant-spin-dot {
        width: 90px;
        height: 90px;
      }

      .ant-spin-dot-item {
        width: 80px;
        filter: blur(6px);
        height: 80px;
        background-color: var(--primary-color);
      }
    }

    .ant-spin,
    .ant-spin-spinning {
      transition: all 0.3s ease-in-out;
    }
  }
}
