.layout {
  position: relative;

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 280px;
    z-index: 16;
    transition: all 0.3s ease-in-out;
  }

  .wrapper {
    padding-left: 280px;
    transition: all 0.3s ease-in-out;

    main {
      min-height: 100vh;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      padding: 12px 24px 24px;
      padding-top: 100px;

      > div {
        position: relative;
        z-index: 3;
      }

      //&:after {
      //  content: '';
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  width: 100%;
      //  height: 100%;
      //  background-color: rgba(27, 27, 28, 1);
      //  filter: blur(20px);
      //  opacity: 0.1;
      //  z-index: 2;
      //  transform: scale(1.1);
      //  -webkit-font-smoothing: antialised;
      //  backface-visibility: hidden;
      //}
    }
  }
}

.layout[data-page='/app/trading-station/smart-money'] {
  .sidebar {
    left: -999px;
    transition: all 0.3s ease-in-out;
  }

  .wrapper {
    padding-left: 0;
    transition: all 0.3s ease-in-out;

    main {
      padding-left: 0;
      padding-right: 0;
      padding-top: 60px;
      min-height: calc(100vh - 50px);
    }
  }
}

@media screen and (max-width: 1024px) {
  .layout {
    position: relative;

    .sidebar {
      position: fixed;
      top: 0;
      left: -300px;
      height: 100%;
      width: 280px;
      z-index: 16;
      background: var(--card-bg);
      transition: all 0.3s ease-in-out;
    }
    .sidebar[data-open='true'] {
      left: 0;
      transition: all 0.3s ease-in-out;
    }

    .wrapper {
      padding-left: 0;

      main {
        padding: 12px 0 24px;
        padding-top: 80px;

        > div {
          position: relative;
          z-index: 3;
        }
      }
    }
  }
}
