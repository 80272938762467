.container__main {
  max-width: 1400px;
}

.container__drawer-details {
  max-width: 1600px;
}

[data-page='/app/trading-station/smart-money'] {
  .container__main {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
