/**
 * Bootstrap
 */
@import '~bootstrap/scss/functions';
$body-text-align: 'left';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api';
/**
 * Ant Design
 */
@import '~antd/dist/antd.min.css';
/**
 * Animate.css
 */
@import 'animate.css/animate.css';
/**
 * React Quill
 */
@import '~react-quill/dist/quill.snow.css';
/**
 * App Styles
 */
@import 'theme';
@import 'base/index';
@import 'antd/index';

.blurred {
  padding: 20px;

  .row .col-12 * {
    opacity: 0.6 !important;
    filter: blur(6px);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: linear-gradient(
      -45deg,
      rgba(20, 20, 23, 0.85) 0%,
      rgba(23, 25, 33, 0.85) 34%,
      rgba(15, 18, 23, 0.85) 71%,
      rgba(22, 22, 23, 0.85) 99%
    );
  }
}

.blurred-new-report {
  padding: 48px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  &__title {
    z-index: 999;
    color: #fff;
  }
}
